<template>
  <div class="GroupOverview">
    <daohang></daohang>
    <banner2 :bannerH="h1" :bannerP="P1" :bannerImg="Img1"></banner2>
    <!-- 关于企业 -->
    <div class="about-bg">
      <div class="about" id="about">
        <div class="about-top">
          <div class="about-top-l">
            <span> 关于企业 </span>
            <p>
              打造前程集团公司是一家集金融、科技、电商、职教、幼教等为一体的集团化公司。在国家“科教兴国，金融助力”的大政策背景下，通过创新金融产品助力产业升级及国民经济转型，促进社会可持续发展；以科技创新推进智慧建设，以电商创新平台带动区域经济特色发展，以产业带动专业建设，深化产业与教育深度融合，以教育助力国家现代化建设。集团公司秉承脚踏实地、志存高远的企业精神，落实内涵建设，坚持守正创新，通过校企合作共建专业、共建二级学院、共建附属幼教机构等模式，致力于让人才更有价值，让科技、教育惠及大众，走出了一条特色化的发展道路。
            </p>
          </div>
          <div class="about-top-r">
            <div class="img-yinying"></div>
            <img
              src="https://img.js.design/assets/img/613eaa7ba9696d53260506a9.png"
              alt=""
            />
          </div>
        </div>
        <div class="about-down">
          <div class="item">
            <span class="item-1">30</span>
            <span class="item-2">+</span>
            <span class="item-3">全国校区</span>
          </div>
          <div class="item2">
            <span class="item-1">10</span>
            <span class="item-2">+</span>
            <span class="item2-3">覆盖城市</span>
          </div>
          <div class="item3">
            <span class="item-1">7000</span>
            <span class="item-2">+</span>
            <span class="item3-3">优秀讲师</span>
          </div>
          <div class="item4">
            <span class="item-1">80</span>
            <span class="item-2">万</span>
            <span class="item4-3">服务学生</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业文化 -->
    <div class="qywh" id="CultureInfo">
      <h1>企业文化</h1>
      <div class="qywh-item">
        <div class="qywh-item1">
          <span><img src="../assets/img/编组 5.png" alt="" /></span>
          <p class="qywh-text">企业定位</p>
          <p class="qywh-text2">
            金融、科技、电商、职教、幼教协同发展，厚德思进
          </p>
        </div>
        <div class="qywh-item1">
          <span><img src="../assets/img/编组 10.png" alt="" /></span>
          <p class="qywh-text">企业理念</p>
          <p class="qywh-text2 text2-2">
            责任&ensp;&ensp;感恩 守正 &ensp;创新 融合&ensp;&ensp;发展
          </p>
        </div>
        <div class="qywh-item1">
          <span><img src="../assets/img/编组 14.png" alt="" /></span>
          <p class="qywh-text">企业使命</p>
          <p class="qywh-text2">
            &ensp;&ensp;&ensp;科技创新引领产业发展 <br />&ensp;&ensp;&ensp;
            产教融合培育技能人才
          </p>
        </div>
        <div class="qywh-item1">
          <span><img src="../assets/img/编组 15.png" alt="" /></span>
          <p class="qywh-text">企业愿景</p>
          <p class="qywh-text2">
            &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;汇才聚智 赋能产业
          </p>
        </div>
      </div>
    </div>
    <!-- 总裁寄语 -->
    <div class="President">
      <div class="President_content">
        <div class="President-title">
          <p>总裁寄语</p>
        </div>
        <div class="President-text">
          <p>
            打造前程集团公司自成立以来，始终保持战略定力，调整和优化产业结构，丰富产业布局，完成了金融、科技、电商、职教、幼教各业务板块的协同发展，以金融助力区域经济建设，以科技推进智慧建设，以产业带动专业建设，实现产教深度融合。<br />
            面对复杂多样、瞬息万变的市场形势，集团公司将保持既定的战略方向，发扬“脚踏实地、志存高远”的企业精神，坚持“责任、感恩、守正、创新、融合、发展”的企业理念，坚守“科技创新引领产业发展，产教融合培育技能人才”的企业使命，传承精髓，创新发展。未来，前程人要勇做守正传承的孺子牛、创新发展的拓荒牛、艰苦奋斗的老黄牛；立德树人守初心，守正创新担使命；有打硬仗、闯难关的坚韧，敢担当、善作为的干劲；脚踏实地，创新不辍，实现“汇才聚智，赋能产业”的愿景，开创集团公司发展的新高度。
          </p>

          <div class="President-text2">
            <span>执行总裁</span>
            <!-- <img src="../assets/img/杨刚.png" alt="" /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="DevelopmentHistory" id="history">
      <div class="DevelopmentHistory_cont">
        <h1>发展历程</h1>
        <div class="DevelopmentHistory_cont_img">
          <img
            src="../assets/img/leftjiantou2.png"
            class="DevelopmentHistory_cont_img1"
          />
          <img
            src="../assets/img/rightjiantou.png"
            class="DevelopmentHistory_cont_img2"
          />
        </div>
        <div class="DevelopmentHistory_cont_ul">
          <ul>
            <p>2010</p>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
          </ul>
          <ul>
            <p>2010</p>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
          </ul>
          <ul>
            <p>2010</p>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
          </ul>
          <ul>
            <p>2010</p>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
          </ul>
          <ul>
            <p>2010</p>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
            <li>全年收入超过1亿人民币</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <footer2></footer2>
  </div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
export default {
  name: "App",
  data() {
    return {
      h1: "集团概况",
      P1: "欢迎加入我们",
      // Img1: require("../assets/img/bannerjituan.png"),
    };
  },
  components: {
    daohang,
    footer2,
    banner2,
  },
  created() {
    this.$nextTick(() => {
      this.toLocal();
    });
  },
  mounted() {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("scroll", _this.handleScroll);
    });
  },
  methods: {
    toLocal() {
      //查找存储的锚点id
      let Id = localStorage.getItem("toId");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
        localStorage.setItem("toId", "");
      }
    },
  },
 

};
</script>

<style scoped>
.GroupOverview {
  width: 1920px;
  margin: 0 auto;
}
.about {
  height: 700px;
  position: relative;
  margin: 100px 200px;
}

.about-bg {
  background-image: url("../assets/img/蒙版.png");
}

.about-top {
  position: relative;
  display: flex;
}

.about-top-l {
  position: absolute;
  left: 20px;
  top: 85px;
  width: 611px;
  height: 442px;
}

.about-top-l > span {
  font-size: 32px;
}

.about-top-l > p {
  font-size: 16px;
  line-height: 40px;
}

.img-yinying {
  position: absolute;
  top: 58px;
  left: -17px;
  width: 17px;
  height: 408px;
  background: #f8f8f8;
}

.about-top-r {
  position: absolute;
  right: -110px;
}

.about-top-r img {
  width: 487px;
  height: 517px;
}

.about-down {
  position: absolute;
  bottom: 20px;
}

.item {
  top: 236px;
  position: relative;
}

.item2 {
  top: 158px;
  left: 328px;
  position: relative;
}

.item3 {
  top: 79px;
  left: 645px;
  position: relative;
}

.item4 {
  left: 1049px;
  position: relative;
}

.item-1 {
  color: rgba(35, 51, 127, 1);
  font-size: 60px;
}

.item-2 {
  position: absolute;
  color: rgba(35, 51, 127, 1);
  font-size: 16.8px;
}

.item-3 {
  position: absolute;
  left: 88px;
  top: 41px;
  color: rgba(102, 102, 102, 1);
  font-size: 20px;
  width: 100px;
}

.item2-3 {
  position: relative;
  color: rgba(102, 102, 102, 1);
  left: 18px;
  font-size: 20px;
}

.item3-3 {
  position: relative;
  color: rgba(102, 102, 102, 1);
  left: 18px;
  font-size: 20px;
}

.item4-3 {
  position: relative;
  color: rgba(102, 102, 102, 1);
  left: 18px;
  font-size: 20px;
}

.qywh {
  width: 100%;
  height: 598px;
  background-color: rgba(248, 248, 248, 1);
}

.qywh h1 {
  color: rgba(68, 68, 68, 1);
  font-size: 32px;
  line-height: 134px;
  text-align: center;
}

.qywh-item {
  margin: 30px 200px;

  justify-content: space-between;
  display: flex;
}

.qywh-item1 {
  margin-right: 16px;
  position: relative;
  width: 288px;
  height: 348px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.qywh-item1 span {
  position: absolute;
  left: 91px;
  top: 30px;
  display: inline-block;
  border-radius: 100%;
  width: 90px;
  height: 90px;
  background-color: rgba(52, 145, 229, 1);
}

.qywh-item1 span > img {
  border-radius: 100%;
  height: 100%;
  width: 100%;
}

.qywh-text {
  position: absolute;
  top: 138px;
  left: 91px;
  width: 100px;
  color: rgba(68, 68, 68, 1);
  font-size: 24px;
}

.qywh-text2 {
  position: absolute;
  top: 192px;
  left: 10px;
  text-align: center;
  color: rgba(68, 68, 68, 1);
  font-size: 18px;
  line-height: 28px;
}

.text2-2 {
  width: 91px;
  line-height: 30px;
  position: absolute;
  left: 92px;
}

/* 总裁寄语 */
/* .President {
  width: 100%px;
  height: 801px;
  background-image: url(../assets/img/摄图网_500941399_banner_企业文化背景（非企业商用）.png);
  background-size: 100% 801px;
  padding-top: 84px;
} */

.President-title > p {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 45px;
  text-align: center;
}

.President-text {
  width: 1200px;
  height: 465px;
  margin: 55px auto;
  background: #ffffff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.President-text > p {
  width: 1000px;
  height: 262px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
  margin: 0 auto;
  padding-top: 71px;
}

.President-text2 {
  height: 50px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
  text-align: center;
  margin-top: 42px;
  position: relative;
}
.President-text2 > span {
  position: absolute;
  left: 491px;
  top: 15px;
}
.President-text2 > img {
  position: absolute;
}
/* 发展历程 */
.DevelopmentHistory {
  width: 1920;
  height: 527px;
  background-image: url("../assets/img/fazhan_bg.png");
}
.DevelopmentHistory_cont {
  width: 1200px;
  margin: 0 auto;
}
.DevelopmentHistory_cont_img img {
  margin-top: 61px;
  width: 32px;
  height: 32px;
}
.DevelopmentHistory_cont_img1 {
  float: left;
}
.DevelopmentHistory_cont_img2 {
  float: right;
}
.DevelopmentHistory_cont > h1 {
  text-align: center;
  margin-top: 64px;

  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 45px;
}
.DevelopmentHistory_cont_ul {
  display: flex;
  margin-top: 12px;
  margin-left: 59px;
  justify-content: space-around;
}
.DevelopmentHistory_cont_ul ul p {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #23337f;
  line-height: 33px;
}

.DevelopmentHistory_cont_ul li {
  margin-top: 8px;
  list-style-type: none;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  line-height: 24px;
}
</style>
